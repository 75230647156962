/* global  itemProxyComponent */
class itemVarietiesComponent extends itemProxyComponent {

    static name() {
        return "itemVarietiesComponent";
    }

    static componentName() {
        return "itemVarietiesComponent";
    }

    mounted() {
        return function () {
            this.loadVarities();
            this.getItemVariationImage();
            this.loadFirstOptions();
        };
    }

    getWatch() {
        return  {
            "item": function () {
                this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code) != -1;
                this.loadVarities();
            },
            "Qty":function () {
                if(this.controlStock && this.canUseOrderQtyFormula != true)
                    if(Number.isInteger(this.item.Qty) && this.item.Qty <= this.Qty) {
                        this.Qty = this.item.Qty;
                        this.alertShowMessage("Maximum item quantity reached");
                    }
            }
        };
    }

    getData() {
        let superData = super.getData();
        return function () {
            return Object.assign(superData(),{
                selectByFirtVariationByDefault: false,
                imageByVariation:{},
                itemVarieties:[],
                options: {},
                selectedColor: null,
                selecteds: [],
                selected: null,
            });
        };
    }

    loadVarietiesPrices(){
        let storeItem = this.$store.getters.getItemByCode(this.item.Code);
        if(storeItem) {
            if(storeItem.Classification)
                this.$store.dispatch("updateCategory",this.$store.getters.getCategoryDataByCode(storeItem.Classification.split(',')[0]));

            if(this.$store.getters.getSetting.ShowPriceByDefault || this.$store.getters.getIsLogin){

                let  itemWithOutPrice = [];
                if(!storeItem.isCalculate) {
                    itemWithOutPrice.push(storeItem);
                }

                if(this.item.RelatedItems) {
                    for (let relatedCode of this.item.RelatedItems.split(',')) {
                        let relatedItem = this.$store.getters.getItemByCode(relatedCode);
                        if (relatedItem && !relatedItem.isCalculate) {
                            itemWithOutPrice.push(relatedItem);
                        }
                    }
                }

                for (let itemVariant of this.itemVarieties) {
                    let itemVariantStore = this.$store.getters.getItemByCode(itemVariant.Code);
                    if (itemVariantStore && itemVariantStore.isCalculate === false) {
                        itemWithOutPrice.push(itemVariantStore);
                    }
                }

                if(itemWithOutPrice.length > 0) {
                    this.loadingPrice = true;
                    this.$store.dispatch("updatePrices", itemWithOutPrice).then((response) => {
                        this.loadingPrice = false;
                    });
                }
            }
        }
        if(this.selectByFirtVariationByDefault)
            this.loadFirstOptions();
    }

    selectVariation(event,option){
        if(event.target.value)
            this.selecteds[option] = event.target.value;
        else{
            this.selecteds[option] = event.target.id;
            this.selectedColor = event.target.id;
        }
        const filteredProducts = this.itemVarieties.filter((item) => {
            let filter = [];
            for(let concept of Object.keys(this.selecteds)){
                filter.push(item.Variants.filter((x)=> x.Concept == concept && x.Code == this.selecteds[concept]).length > 0);
            }
            return filter.reduce((acumulator, x)=>{ return x && acumulator;});
        });
        if(filteredProducts.length === 1)
            this.selected =   this.$store.getters.getItemByCode(filteredProducts[0].Code);
        else
            this.selected =  null;
    }

    itemQtyIncrement(){
        const maxQty = this.getMaxStockByCode(this.selected.Code);
        if(Number.isInteger(this.Qty) && this.Qty + 1 <= maxQty)
            this.Qty += 1;
        else {
            if(maxQty === 0)
                this.alertShowMessage("ITEM_STOCK_ZERO");
            else
                this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
        }
    }

    addItemToCar(){
        if(this.selected){
            let itemSelected = this.$store.getters.getItemByCode(this.selected.Code);
            if(isNaN(this.Qty) || this.Qty == 0) {
                this.Qty = 0;
                return;
            }
            let formaterQty  = Math.round((parseFloat(this.Qty) + Number.EPSILON) * 100) / 100;
            if(this.item.OnlyIntegerValues === 1) {
                formaterQty = parseInt(this.Qty);
            }
            if(this.controlStock) {
                const maxQty = this.getMaxStockByCode(this.selected.Code);
                if (formaterQty > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    this.Qty = maxQty;
                    return;
                } else {
                    if (maxQty <= 0) {
                        const item = this.$store.getters.getItemByCode(this.selected.Code);
                        this.alertShowMessage([`This item {{$1}} is not longer available`,item.Name]);
                        return;
                    }
                }
            }
            itemSelected.carQty = formaterQty;
            if(this.canUseOrderQtyFormula)
                for(let field of this.getQtyFormulaFields)
                    itemSelected[field] =  this.item[field];
            this.$store.dispatch('addVarietyToCar', Object.assign({},itemSelected));
        } else
            this.alertShowMessage(`Please the items variations`);
    }

    loadVarities(){
        const processVarietiesInfo = (varieties) =>{
            let tempOptions = {};
            let availableOptions = [];
            varieties.forEach((x)=>availableOptions = availableOptions.concat(x.Variants));
            for(let concept of this.item.Concepts.split(",")){
                if(!tempOptions[concept]){
                    tempOptions[concept] = {};
                }
                for(let variate of availableOptions) {
                    if(concept == variate.Concept && !tempOptions[concept][variate.Code])
                        tempOptions[concept][variate.Code] = variate.Comment;
                }
            }
            this.options = tempOptions;
            this.itemVarieties = varieties;
        };
        if(this.item) {
            const varietiesInfoCache = this.item.VariationData || this.$store.getters.getVairetiesFromCache(this.item.Code);

            if(varietiesInfoCache) {
                processVarietiesInfo(varietiesInfoCache);
                this.loadVarietiesPrices();
            } else {
                this.$store.dispatch("loadVarieties", this.item.Code).then((varietiesInfo) => {
                    processVarietiesInfo(varietiesInfo);
                    this.getItemVariationImage();
                    this.loadVarietiesPrices();
                });
            }
            
        }
    }

    loadFirstOptions(){
        for(let concept of Object.keys(this.options)) {
            for(let varietyCode of Object.keys(this.options[concept])) {
                if(concept !== "COLOR" && !this.selected) {
                    this.selectVariation({target :{value: varietyCode}}, concept);
                    break;
                }
            }
        }
    }

    getItemVariationImage() {
        for(let option of Object.keys(this.options).sort()) {
            if (option == 'COLOR') {
                for (let optionKey of Object.keys(this.options[option]).sort()) {
                    if (!this.imageByVariation[`${option}-${optionKey}`]) {
                        for (let itemVariation of this.itemVarieties) {
                            if (itemVariation.Images.length > 0 && itemVariation.Variants.filter(x => x.Code === optionKey && x.Concept === option).length > 0) {
                                this.imageByVariation[`${option}-${optionKey}`] = itemVariation.Images[0];
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    onChangeQty(event){
        if(!isNaN(event.target.value)) {
            const newValue = Number.parseInt(event.target.value,10);
            const maxQty = this.getMaxStockByCode(this.selected.Code);
            if(newValue <= maxQty)
                this.Qty = newValue;
            else {
                if(maxQty === 0)
                    this.alertShowMessage("ITEM_STOCK_ZERO");
                else
                    this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                this.Qty = maxQty;
            }
        } else {
            this.alertShowMessage([`FORMATVALUEERR` ,'Qty']);
            this.Qty = 1;
        }
    }

    getMethods() {
        let superMethods = super.getMethods();
        superMethods['loadFirstOptions'] = this.loadFirstOptions;
        superMethods['selectVariation'] = this.selectVariation;
        superMethods['addItemToCar'] = this.addItemToCar;
        superMethods['loadVarities'] = this.loadVarities;
        superMethods['loadVarietiesPrices'] = this.loadVarietiesPrices;
        superMethods['getItemVariationImage'] = this.getItemVariationImage;
        superMethods['itemQtyIncrement'] = this.itemQtyIncrement;
        return superMethods;
    }

    getTemplate(){
        return `<div class="item-detail" @change.once="loadVarities">
                    <div class="col-12">
                        <div class="container-fluid">
                            <div class="row" v-if="item">
                                <div class="col-md-4 col-sm-12">
                                    <template v-if="!selected">
                                        <itemSliderComponent :useZoom="imgUseZoom" :slideBigImage="imgSlideBigImage" :item="item" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                                    </template>
                                    <template v-else>
                                        <itemSliderComponent :useZoom="imgUseZoom" :slideBigImage="imgSlideBigImage" :item="selected" :stockFlag="showStock" :container="true" :showCarusel="imgShowCarusel"></itemSliderComponent>
                                    </template>
                                </div>
                                <div class="col-md-8 col-sm-12">
                                    <p class="product-title">{{item.Name}}</p>
                                    <div class="product-description">
                                        <p class="subtitle">{{tr("Description")}}</p>
                                        <p class="product-comment" v-if="item.Comment">{{item.Comment}}</p>
                                        <p v-else>{{tr("Article without description.")}}</p>
                                    </div>
                                    <div class="product-details">
                                        <p class="product-code">{{tr("ECOMITEMCODE")}} {{codeToShow}}</p>
                                        <p class="product-code" v-if="$store.getters.showStock">
                                            <template v-if="!selected">
                                                Stock {{item.Qty}}
                                            </template>
                                            <template v-else>
                                                Stock {{selected.Qty}}
                                            </template>
                                        </p>
                                        <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                            <p class="item-price" v-if="!loadingPrice">
                                                <template v-if="!selected">
                                                    <template v-if="item.Qty - item.Reserved > 0">
                                                        Stock {{getMaxStockByCode(item.Code)}}
                                                    </template>
                                                    <template v-else>
                                                        Stock <span class="badge-dark px-1">Agotado</span>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="getMaxStockByCode(selected.Code) > 0">
                                                        Stock {{selected.Qty - selected.Reserved}}
                                                    </template>
                                                    <template v-else>
                                                        Stock <span class="badge-dark px-1">Agotado</span>
                                                    </template>
                                                </template>
                                            </p>
                                        </template>
                                        <template v-if="showClassification">
                                            <p class="subtitle">{{tr('Classifications')}}</p>
                                            <ol class="flex-column">
                                                <template v-for="cat in classificationList">
                                                    <li :key="'item-cat-'+cat.name" @click="filterByCategory(cat)">
                                                        <router-link :to="'/items?category=' + encodeURI(cat.code+'__'+cat.name)">
                                                            {{capitalize(cat.name)}}
                                                        </router-link>
                                                    </li>
                                                </template>
                                            </ol>
                                        </template>
                                    </div>
                                    <template v-if="itemVarieties.length==0">
                                        <div class="loading">
                                            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="row items-varieties">
                                            <div class="col-12">
                                                <template v-for="option in Object.keys(options).sort()">
                                                    <template v-if="option=='COLOR'">
                                                        <div class="form-group justify-content-left row ">
                                                            <label class="col-12" style=" font-size: 1.1rem;" :for="'optionSelect'+option">{{capitalize(option)}}</label>
                                                            <div class="row col-10 col-md-5 col-lg-4 item-color-options">
                                                                <template v-for="optionKey in Object.keys(options[option]).sort()">
                                                                    <div class="col px-1">
                                                                        <template v-if="imageByVariation[option+'-'+optionKey]">
                                                                            <template v-if="selected && selected.Variants && selected.Variants.split(',').indexOf(optionKey)!==-1 && selected.images && selected.images.length > 0">
                                                                                <img :title="options[option][optionKey]" :key="optionKey" :id="optionKey" :src="selected.images[0]" :alt="options[option][optionKey]" class="rounded rounded-circle" v-bind:class="{ 'color-active': selectedColor==optionKey }" width="40" height="40" @click="(event)=>selectVariation(event,option)">
                                                                            </template>
                                                                            <template v-else>
                                                                                <img :title="options[option][optionKey]" :key="optionKey" :id="optionKey" :src="imageByVariation[option+'-'+optionKey]" :alt="options[option][optionKey]" class="rounded rounded-circle" v-bind:class="{ 'color-active': selectedColor==optionKey }" width="40" height="40" @click="(event)=>selectVariation(event,option)">
                                                                            </template>
                                                                        </template>
                                                                        <template v-else>
                                                                            <img :title="options[option][optionKey]" :key="optionKey" :id="optionKey" :src="'../assets/'+optionKey+'_'+options[option][optionKey].replace(' ','')" :alt="options[option][optionKey]" class="rounded rounded-circle" v-bind:class="{ 'color-active': selectedColor==optionKey }" width="40" height="40" @click="(event)=>selectVariation(event,option)">
                                                                        </template>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="form-group row">
                                                            <label class="col-auto col-form-label" :for="'optionSelect'+option">{{capitalize(option)}}</label>
                                                            <div class="col-sm-8 col-md-auto">
                                                                <select :id="'optionSelect'+option" class="form-control" v-on:change="(event)=>selectVariation(event,option)">
                                                                    <option :value="null" selected disabled></option>
                                                                    <template v-for="optionKey in Object.keys(options[option]).sort()">
                                                                        <template v-if="optionKey===Object.keys(options[option])[0]">
                                                                            <option :value="optionKey" selected>{{options[option][optionKey]}}</option>
                                                                        </template>
                                                                        <template v-else>
                                                                            <option :value="optionKey">{{options[option][optionKey]}}</option>
                                                                        </template>
                                                                    </template>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template v-if="selected">
                                                    <p> Cod.Variante : {{selected.Code}}</p>
                                                    <div class="row product-shops" style="align-items: center;">
                                                        <template v-if="alertMsg">
                                                            <div class="col-12 alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                                                        </template>
                                                        <template v-if="canUseOrderQtyFormula">
                                                            <template v-for="field of getQtyFormulaFields">
                                                                <div class="form-group row w-100">
                                                                    <div class="input-group qty-input col-8 col-md-3">
                                                                        <input :ref="field+'-'+item.Code" :placeholder="tr(['Enter {{$1}}',field])" class="form-control" min="1" :max="1000000" type="number" @change="(event) => updateQtyFormula(field, event)">
                                                                    </div>
                                                                    <label :for="'label'+field" class="col-4 col-md-3 col-form-label col-form-label-sm">{{field}}</label>
                                                                </div>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <div class="input-group qty-input col-4 col-sm-6 col-xl-3 col-md-5 pr-0 pr-xl-4">
                                                                <template v-if="controlStock">
                                                                    <template v-if="getMaxStockByCode(selected.Code)">
                                                                        <div class="input-group-prepend d-none d-sm-flex">
                                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                                        </div>
                                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                                        <div class="input-group-append d-none d-sm-flex">
                                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" min='0' :max="0" class="form-control inputch" type="text" disabled>
                                                                        <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                                            {{tr("Without Stock")}}
                                                                        </small>
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="input-group-prepend d-none d-sm-flex">
                                                                        <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                                    </div>
                                                                    <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(selected.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                                    <div class="input-group-append d-none d-sm-flex">
                                                                        <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                                    </div>  
                                                                </template>
                                                            </div>
                                                        </template>
                                                        <span class="cart-button col-8 col-sm-6 col-xl-12 col-md-4 pl-sm-0 pl-sm-3 text-left mt-0 mt-xl-2">
                                                            <button class="btn secondary-btn px-1 px-sm-3 px-xl-5" :disabled="!canShopItem && getMaxStockByCode(selected.Code)" type="button" @click.stop="canShopItem ? addItemToCar() : null"><span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span>{{tr("Load to Cart")}}</button>
                                                        </span>
                                                    </div>
                                                </template>
                                                <div class="social-buttons col-12 text-center text-md-left mt-4 p-0 pl-md-3">
                                                    <a v-if="enableFB" class="social-button mr-1" title="Facebook" target="_blank" rel="nofollow" :href="'https://www.facebook.com/sharer.php?u='+getItemUrl()">
                                                        <i class="icon fab fa-facebook-square"></i>
                                                    </a>
                                                    <a v-if="enableTW" class="social-button" title="Twitter" target="_blank" rel="nofollow" :href="'https://twitter.com/intent/tweet?url='+getItemUrl()">
                                                        <i class="icon fab fa-twitter-square"></i>
                                                    </a>
                                                    <a v-if="enableIG" class="social-button" title="Instagram" target="_blank" rel="nofollow" :href="linkIG">
                                                        <i class="icon fab fa-instagram-square"></i>
                                                    </a>
                                                    <a v-if="enableLK" class="social-button" title="LinkedIn" target="_blank" rel="nofollow" :href="linkLK">
                                                        <i class="icon fab fa-linkedin"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div v-if="relatedItems.length>0" class="related-items w-100 px-3">
                                    <div class="mt-3">
                                        <homeItemCardComponent :section="{internalId:item.Code}" :items="relatedItems" :title="tr('Related Items')" :titleCenter="true" :fullScreen="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
    }
}

itemVarietiesComponent.registerComponent();